import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
    Component,
    EventEmitter,
    HostListener,
    Injector,
    Output,
    SimpleChanges,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CsvExportService } from '../csv-export.service';
import { Table, TableColumnDef } from '../table';

@Component({
    selector: 'sheet',
    templateUrl: './sheet.component.html',
    styleUrls: ['./sheet.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'sheet' }],
})
export class SheetComponent extends Table {
    @Output() onAddRow: EventEmitter<void> = new EventEmitter();
    @Output() onExport: EventEmitter<void> = new EventEmitter();
    @Output() onInsertData: EventEmitter<void> = new EventEmitter();
    @Output() onDeleteData: EventEmitter<void> = new EventEmitter();
    @Output() onUploadData: EventEmitter<void> = new EventEmitter();
    @Output() onDeleteSelection: EventEmitter<any[]> = new EventEmitter();

    editedCell: WritableSignal<SelectedSheetCell> = signal(null);
    selectedCells: WritableSignal<SelectedSheetCell[]> = signal([]);

    shownData: any[];

    allColumns: WritableSignal<TableColumnDef[]> = signal([]);
    visibleColumns = computed(() => {
        let visibleCols = [];
        this.allColumns().forEach((colDef: TableColumnDef) => {
            if (colDef.visible) {
                visibleCols.push(colDef);
            }
        });
        return visibleCols;
    });

    constructor(
        _liveAnnouncer: LiveAnnouncer,
        _injector: Injector,
        private csvService: CsvExportService
    ) {
        super(_liveAnnouncer, _injector);
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (this.data?.data) {
            this.data.data = this.data.data.map((value: any, index: number) => {
                value['__index__'] = index;
                return value;
            });

            this.shownData = this.data.data;
        }
        if (changes['displayedColumns']) {
            let displayedColumns: Array<TableColumnDef> = changes['displayedColumns'].currentValue;
            displayedColumns = displayedColumns.map((colDef: TableColumnDef) => {
                colDef.visible = colDef.visible === undefined ? true : colDef.visible;
                return colDef;
            });
            this.allColumns.set(displayedColumns);
        }
    }

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.sort) {
            this.sort.sortChange.subscribe(() => {
                this.shownData = this.getSortedData([...this.data.data]);
            });
        }
    }

    /**
     * Overrides the default getColumnKeys() function.
     *
     * Only returns those columns that are visible.
     * @returns
     */
    override getColumnKeys(): any[] {
        let keys = [];
        if (this.enableRowSelection) keys.push('select');
        keys = [...keys, ...this.visibleColumns().map(column => column.columnKey)];
        return keys;
    }

    /**
     * Toggles the visibility of a column
     * @param col
     */
    toggleColVisibility(col: TableColumnDef): void {
        let allColumns = [...this.allColumns()];

        const colDef = allColumns.find(c => c.columnKey === col.columnKey);
        colDef.visible = !colDef.visible;

        this.allColumns.set([...allColumns]);
    }

    /**
     * Sorts the given data according to the current sort settings
     * @param data
     * @returns sorted data
     */
    getSortedData(data: any[]): any[] {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort.direction === 'asc';
            const key = this.sort.active;
            return this.compare(a[key], b[key], isAsc);
        });
    }

    /**
     * Simple sort comparator for example ID/Name columns (for client-side sorting)
     * @param a
     * @param b
     * @param isAsc
     * @returns
     */
    compare(a: any, b: any, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * Copies the content of the cell to clipboard
     * @param content
     */
    async copy(content: any): Promise<void> {
        try {
            await navigator.clipboard.writeText(content);
        } catch (err) {
            alert('Error in copying text: ' + err);
        }
    }

    /**
     * Listens to the keyboard events where the seet is shown.
     *
     * The listened keys are: Tab, Enter, Arrow Up/Down/Left/Right
     *
     * @param event KeyboardEvent
     */
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Tab':
                this.moveSelection(1, 0);
                break;
            case 'Enter':
                if (this.selectedCells().length) {
                    if (this.editedCell()) {
                        this.closeCellEdit();
                    } else {
                        const __index__ = this.getIndexByRowIndex(this.selectedCells()[0].rowIndex);
                        this.editCell(
                            this.shownData[__index__],
                            this.selectedCells()[0].key,
                            this.selectedCells()[0].rowIndex
                        );
                    }
                }

                break;
            case 'ArrowUp':
                this.moveSelection(0, -1);
                break;
            case 'ArrowDown':
                this.moveSelection(0, 1);
                break;
            case 'ArrowLeft':
                this.moveSelection(-1, 0);
                break;
            case 'ArrowRight':
                this.moveSelection(1, 0);
                break;
            default:
                break;
        }
    }

    /**
     * Gets the unique index of the row by the rowIndex
     * @param rowIndex
     * @returns
     */
    private getIndexByRowIndex(rowIndex): number {
        const pageSize = this.paginator.pageSize;
        const currentPageIndex = this.paginator.pageIndex;
        return pageSize * currentPageIndex + rowIndex;
    }

    /**
     * Moves the selection with the given parameters
     * @param moveCol
     * @param moveRow
     */
    moveSelection(moveCol: number, moveRow: number): void {
        if (this.editedCell()) {
            this.closeCellEdit();
        }

        if (this.selection.selected.length) {
            this.selection.clear();
        }

        // Default selection first row first col
        if (!this.selectedCells().length) {
            this.selectedCells.update((selectedCells: any[]) => {
                selectedCells.push({
                    index: 0,
                    key: this.visibleColumns()[0].columnKey,
                });
                return selectedCells;
            });
        }
        // Move selection
        else {
            this.selectedCells.update((selectedCells: SelectedSheetCell[]) => {
                // Current selection
                let selectedCell = selectedCells[0];
                // Current keyIndex
                let colIndex = this.visibleColumns().findIndex(
                    (colDef: TableColumnDef) => colDef.columnKey === selectedCell.key
                );
                // Current rowIndex
                let rowIndex = selectedCell.rowIndex;

                // Move column
                // New column not possible
                if (colIndex + moveCol === this.visibleColumns().length) {
                    // If last row, do nothing
                    // If not last row
                    if (rowIndex !== this.data.data.length - 1) {
                        colIndex = 0;
                        rowIndex += 1;
                    }
                } else if (colIndex + moveCol < 0) {
                    // If first row, do nothing
                    // If not first row
                    if (rowIndex !== 0) {
                        colIndex = this.visibleColumns().length - 1;
                        rowIndex -= 1;
                    }
                }
                // New column possible
                else {
                    colIndex += moveCol;
                }

                // Move row
                if (rowIndex + moveRow !== this.data.data.length && rowIndex + moveRow !== -1) {
                    rowIndex += moveRow;
                }

                const __index__ = this.getIndexByRowIndex(rowIndex);

                selectedCells = [
                    {
                        __index__: this.shownData[__index__].__index__,
                        rowIndex,
                        key: this.visibleColumns()[colIndex].columnKey,
                    },
                ];

                return selectedCells;
            });
        }
    }

    /**
     * Creates a form in the cell that is selected
     * @param row Object of the row
     * @param key Key of the column
     * @param index Index of the row
     */
    editCell(row: any, key: string, index: number): void {
        if (this.selection.selected.length) {
            this.selection.clear();
        }
        if (!this.selectedCells().length) {
            this.toggleCellSelection(undefined, key, row.__index__, index);
        }
        this.editedCell.set({
            row,
            rowIndex: index,
            __index__: row.__index__,
            key,
        });

        let editField: FormlyFieldConfig = {
            type: 'input',
            key,
            focus: true,
            props: {
                blur: () => {
                    this.closeCellEdit();
                },
            },
        };

        let colDef = this.visibleColumns().find((cD: TableColumnDef) => cD.columnKey === key);

        switch (colDef.contentType) {
            case 'int':
                editField.props.type = 'number';
                break;
            case 'uuid':
                editField.props.type = 'text';
                editField.props.pattern =
                    '^[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}$';
                break;
            case 'text':
                editField.props.type = 'text';
                break;
            case 'timestamp':
                editField.type = 'datepicker';
                editField.props.blur = undefined;
                break;
            case 'float':
                editField.props.type = 'number';
                editField.props.step = 0.01;
                break;
            case 'decimal':
                editField.props.type = 'number';
                editField.props.step = 0.0001;
                break;
        }

        this.fields = [editField];
    }

    /**
     * Destroys the form that is shown in the cell.
     */
    closeCellEdit(): void {
        if (this.form.value[this.editedCell().key]) {
            this.data.data[this.editedCell().__index__][this.editedCell().key] =
                this.form.value[this.editedCell().key];
            let dataToEmit = { ...this.data.data[this.editedCell().__index__] };
            delete dataToEmit.__index__;
            this.afterDataChanged.emit(dataToEmit);
        }

        this.editedCell.set(null);
        this.fields = [];
        this.form = new FormGroup({});
    }

    /**
     * Toggles the selection of a cell
     * @param event
     * @param key columnKey
     * @param __index__ unique index of the row
     * @param rowIndex index of the row
     */
    toggleCellSelection(
        event: MouseEvent | undefined,
        key: string,
        __index__: number,
        rowIndex: number
    ): void {
        if (
            this.editedCell() &&
            this.editedCell().__index__ === __index__ &&
            this.editedCell().key === key
        ) {
            event.stopPropagation();
        } else if (this.editedCell()) {
            this.closeCellEdit();
        }
        this.selectedCells.update((selectedCells: SelectedSheetCell[]) => {
            const selectedCell = selectedCells.find(
                (selectedCell: any) =>
                    selectedCell.__index__ === __index__ && selectedCell.key === key
            );
            if (selectedCell) {
                selectedCells.splice(selectedCells.indexOf(selectedCell), 1);
            } else {
                const newSelection = {
                    __index__: __index__,
                    rowIndex,
                    key,
                };
                if (event?.shiftKey) {
                    selectedCells.push(newSelection);
                } else {
                    selectedCells = [newSelection];
                }
            }
            return selectedCells;
        });
    }

    /**
     * Checks if a cell is selected or not
     * @param key columnKey
     * @param __index__ unique index of the row
     * @param rowIndex index of the row
     * @returns
     */
    isCellSelected(key: string, __index__: number, rowIndex: number): boolean {
        return this.selectedCells().some(
            (selectedCell: SelectedSheetCell) =>
                selectedCell.__index__ === __index__ &&
                selectedCell.key === key &&
                selectedCell.rowIndex === rowIndex
        );
    }

    /**
     * Exports all the rows into CSV
     */
    exportToCSV(): void {
        const data = this.data.data.map(row => {
            let obj = {};
            this.visibleColumns().forEach(
                (column: TableColumnDef) => (obj[column.columnKey] = row[column.columnKey])
            );
            return obj;
        });
        this.csvService.downloadFile(data, 'data_export.xlsx');
    }

    /**
     * Exports the selected rows into CSV
     */
    exportSelectedRowsToCSV(): void {
        if (this.selection.selected.length) {
            const data = this.selection.selected.map(row => {
                let obj = {};
                this.visibleColumns().forEach(
                    (column: TableColumnDef) => (obj[column.columnKey] = row[column.columnKey])
                );
                return obj;
            });
            this.csvService.downloadFile(data, 'data_export.xlsx');
        } else {
            alert('There is no row selected!');
        }
    }

    /**
     * Emits an event for adding a row
     */
    addRow(): void {
        this.onAddRow.emit();
    }

    insertData() {
        this.onInsertData.emit();
    }
    deleteData() {
        this.onDeleteData.emit();
    }

    uploadFile(event: any) {
        this.onUploadData.emit(event);
    }

    exportFile() {
        this.onExport.emit();
    }

    deleteSelectedRows(): void {
        this.onDeleteSelection.emit(this.selection.selected);
        this.selection.clear();
    }

    get fileInput() {
        return document.getElementById('file') as HTMLInputElement;
    }
}

/**
 * Interface for defining a selected cell in the Sheet
 */
export interface SelectedSheetCell {
    /**
     * Unique index of data row
     */
    __index__?: number;
    /**
     * Index of the shown row in the sheet
     */
    rowIndex: number;
    /**
     * Key of the column
     */
    key: string;
    /**
     * Object of the row
     */
    row?: any;
}
