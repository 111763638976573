<div class="h-full flex flex-col gap-2">
    <form *ngIf="filter" [formGroup]="form">
        <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
    </form>
    <div
        class="w-full h-full overflow-auto tableContainer"
        cdkDropList
        (cdkDropListDropped)="dropTable($event)">
        <table
            mat-table
            matSort
            [dataSource]="dataSource()"
            (matSortChange)="announceSortChange($event)">
            <!-- expand column -->
            <!-- <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef class="icon-column"> </th>
                <td mat-cell *matCellDef="let row" class="icon-column" cdkDrag [cdkDragData]="row">
                    <button mat-icon-button [style.visibility]="!row.expandable ? 'hidden' : ''"
                        (click)="treeControl.toggle(row)">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{ treeControl.isExpanded(row) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </button>
                </td>
            </ng-container> -->

            <!-- checkbox column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="icon-column">
                    <mat-checkbox
                        *ngIf="enableMultipleRowSelection"
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="icon-column" cdkDrag [cdkDragData]="row">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? toggleRow($event, row) : null"
                        [checked]="selection.isSelected(row)"
                        [style.marginLeft.px]="
                            row.level > 0 && !row.expandable ? row.level * 32 : 0
                        ">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- other columns -->
            <ng-container
                *ngFor="let column of displayedColumns; trackBy: customTrackBy; index as i"
                [matColumnDef]="column.columnKey">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    [disabled]="!column.enableSorting"
                    class=""
                    >{{ column.columnName }}</th
                >
                <td mat-cell *matCellDef="let row; index as index" cdkDrag [cdkDragData]="row">
                    <ng-container
                        *ngIf="
                            getCustomColumn(column.columnKey) as content;
                            else defaultColumnContentCell
                        "
                        [ngTemplateOutlet]="content.templateRef"
                        [ngTemplateOutletContext]="{ $implicit: row }">
                    </ng-container>
                    <ng-template #defaultColumnContentCell>
                        <div class="flex gap-2">
                            @if (i === 0) {
                                @if (treeView) {
                                    <button
                                        mat-icon-button
                                        [style.visibility]="!row.expandable ? 'hidden' : ''"
                                        (click)="treeControl.toggle(row)"
                                        [style.marginLeft.px]="
                                            row.level > 0 && !row.expandable ? row.level * 32 : 0
                                        ">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{
                                                treeControl.isExpanded(row)
                                                    ? 'expand_more'
                                                    : 'chevron_right'
                                            }}
                                        </mat-icon>
                                    </button>
                                    @if (enableRowSelection) {
                                        <mat-checkbox
                                            (click)="$event.stopPropagation()"
                                            (change)="$event ? toggleRow($event, row) : null"
                                            [checked]="selection.isSelected(row)">
                                        </mat-checkbox>
                                    }
                                }
                            }
                            <p [innerHTML]="getDefaultColumnCellValue(row, column)"></p>
                        </div>
                    </ng-template>
                </td>
            </ng-container>

            <!-- table definition -->
            <tr mat-header-row *matHeaderRowDef="getColumnKeys()"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: getColumnKeys()"
                [ngClass]="{ selected: selection.isSelected(row) }"
                [cdkContextMenuDisabled]="!tableConfig?.contextMenuConfig?.enableContextMenu"
                [cdkContextMenuTriggerFor]="menu"
                [cdkContextMenuTriggerData]="{ row: row }"
                cdkDrag
                [cdkDragData]="row"></tr>
        </table>
    </div>

    <!-- Paginator -->
    <mat-paginator
        *ngIf="enablePagination"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [pageSize]="10"
        aria-label="Select page of users"></mat-paginator>
</div>

<!-- Context menu (on right click of tree node) -->
<ng-template #menu let-row="row">
    <mycdk-context-menu
        [node]="row"
        [menuConfig]="tableConfig?.contextMenuConfig"
        [menuItemClick]="contextMenuItemClicked.bind(this)"></mycdk-context-menu>
</ng-template>
