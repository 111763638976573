import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { Table } from '../table';

@Component({
    selector: 'custom-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent extends Table implements OnDestroy {
    private _destroy: Subject<void> = new Subject<void>();

    @Input() filter: boolean = false;

    form = new FormGroup({});

    fields: FormlyFieldConfig[] = [];

    model: any = {
        filterValue: '',
    };

    constructor(_liveAnnouncer: LiveAnnouncer, _injector: Injector) {
        super(_liveAnnouncer, _injector);

        this.setUpFields();

        this.form.valueChanges.pipe(takeUntil(this._destroy)).subscribe(() => {
            const filteredData = this.data.data.filter(item => {
                let results = Object.keys(item).map(key => {
                    if (typeof item[key] == 'string') {
                        const filterValue = this.model.filterValue
                            ? this.model.filterValue.toLowerCase()
                            : '';
                        if (item[key].toLowerCase().includes(filterValue)) {
                            return true;
                        }
                    }
                    return false;
                });

                return results.some(result => result == true);
            });

            this.dataSource().data = filteredData;
        });
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    private setUpFields() {
        this.fields = [
            {
                key: 'filterValue',
                type: 'input',
                props: {
                    label: 'Filter Value',
                    placeholder: 'Filter Value',
                },
            },
        ];
    }
}
