import { NgModule } from '@angular/core';
import { TableComponent } from './table/table.component';
import { SheetComponent } from './sheet/sheet.component';
import { CdkMenuItem, CdkMenu, CdkContextMenuTrigger } from '@angular/cdk/menu';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { MycdkContextMenuComponent } from '@em4cloud/my-cdk';
import { FuseAlertComponent } from '@fuse/components/alert';

@NgModule({
    declarations: [TableComponent, SheetComponent],
    imports: [
        CommonModule,
        MatTableModule,
        NgFor,
        NgIf,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormlyModule,
        FormlyMaterialModule,
        CdkMenuItem,
        CdkMenu,
        CdkContextMenuTrigger,
        MatNativeDateModule,
        FormlyMatDatepickerModule,
        MatMenuModule,
        MatTooltipModule,
        TranslocoModule,
        MycdkContextMenuComponent,
        FuseAlertComponent,
    ],
    exports: [TableComponent, SheetComponent],
})
export class GridViewsModule {}
