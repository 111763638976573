import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CsvExportService {
    constructor() {}

    /**
     * Exports the given data array into CSV and downloads it.
     * @param data
     * @param filename
     */
    downloadFile(data: any[], filename = 'data.csv'): void {
        let csvData = this.convertToCSV(data);
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(blob);
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    /**
     * Converts the data array to CSV
     * @param data
     * @returns
     */
    convertToCSV(data: any[]): string {
        let csv = '';
        // headers
        for (let i = 0; i < data.length; i++) {
            let row = '';
            for (let j = 0; j < Object.keys(data[i]).length; j++) {
                if (row !== '') {
                    row += ',';
                }
                row += '"' + Object.values(data[i])[j] + '"';
            }
            csv += row + '\r\n';
        }
        return csv;
    }
}
