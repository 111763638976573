<div *transloco="let t; read: 'sheet'" class="h-full flex flex-col gap-2">
    <div class="flex flex-row gap-2 bg-dialog my-auto">
        <button
            mat-icon-button
            [matMenuTriggerFor]="colVisibilityMenu"
            [matTooltip]="t('toggleColVisibility')"
            aria-label="Toggle visibility">
            <mat-icon svgIcon="mat_solid:visibility"></mat-icon>
        </button>
        <mat-menu #colVisibilityMenu="matMenu">
            <button
                mat-menu-item
                *ngFor="let col of allColumns()"
                (click)="toggleColVisibility(col)">
                <span>
                    {{ col.columnName }}
                </span>
                <mat-icon
                    [matTooltip]="t('show')"
                    *ngIf="!col.visible"
                    svgIcon="mat_solid:visibility"></mat-icon>
                <mat-icon
                    [matTooltip]="t('hide')"
                    *ngIf="col.visible"
                    svgIcon="mat_solid:visibility_off"></mat-icon>
            </button>
        </mat-menu>
        <!-- <button
            mat-icon-button
            [matMenuTriggerFor]="exportMenu"
            [matTooltip]="t('export')"
            aria-label="Export">
            <mat-icon svgIcon="mat_solid:download"></mat-icon>
        </button>
        <mat-menu #exportMenu="matMenu">
            <button mat-menu-item (click)="exportToCSV()">
                <span>
                    {{ t('exportAsCSV') }}
                </span>
            </button>
            
        </mat-menu> -->
        <button mat-icon-button (click)="addRow()" [matTooltip]="t('addRow')">
            <mat-icon svgIcon="mat_solid:add_circle"></mat-icon>
        </button>
        <button mat-icon-button (click)="insertData()" [matTooltip]="t('save')">
            <mat-icon svgIcon="mat_solid:save"></mat-icon>
        </button>
        <button mat-icon-button (click)="deleteData()" [matTooltip]="t('delete')">
            <mat-icon svgIcon="mat_solid:delete"></mat-icon>
        </button>
        <button mat-icon-button (click)="exportFile()" [matTooltip]="t('export')">
            <mat-icon svgIcon="mat_solid:download"></mat-icon>
        </button>
        <button mat-icon-button (click)="fileInput.click()" [matTooltip]="t('upload')">
            <mat-icon svgIcon="mat_solid:upload"></mat-icon>
        </button>
        <input type="file" id="file" (change)="uploadFile($event)" accept=".csv, .xlsx" hidden />

        <div *ngIf="selection.selected.length > 0" class="flex flex-row gap-2 px-2 border-l">
            <!-- <button
                mat-icon-button
                (click)="exportSelectedRowsToCSV()"
                [matTooltip]="t('exportSelectionAsCSV')">
                <mat-icon svgIcon="mat_solid:download"></mat-icon>
            </button> -->
            <button
                mat-icon-button
                (click)="deleteSelectedRows()"
                [matTooltip]="t('deleteSelectedRows')">
                <mat-icon svgIcon="mat_solid:delete"></mat-icon>
            </button>
        </div>
        <!-- <button mat-raised-button [color]="'primary'" class="w-max" (click)="fileInput.click()">
            {{ t('createTableFromSchema') }}
        </button> -->
    </div>
    <fuse-alert
        *ngIf="!dataSource().data.length"
        [appearance]="'soft'"
        [type]="'basic'"
        [showIcon]="false"
        class="text-center block my-auto w-full">
        {{ t('noData') }}
    </fuse-alert>
    <div *ngIf="dataSource().data.length" class="w-full h-full overflow-auto border rounded-lg">
        <table
            mat-table
            matSort
            [dataSource]="dataSource()"
            (matSortChange)="announceSortChange($event)">
            <!-- checkbox column -->
            <ng-container matColumnDef="select" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef class="w-14 border-r sheetIndexCol">
                    <button *ngIf="enableMultipleRowSelection" mat-button (click)="toggleAllRows()">
                        <mat-icon svgIcon="mat_outline:table_rows"></mat-icon>
                    </button>
                </th>
                <td
                    mat-cell
                    *matCellDef="let row; let rowIndex = index"
                    class="border-r sheetIndexCol"
                    [ngClass]="selection.isSelected(row) ? 'selected' : ''">
                    <button mat-button (click)="toggleRow($event, row)">
                        {{ rowIndex + 1 }}
                    </button>
                </td>
            </ng-container>

            <!-- other columns -->
            <ng-container
                *ngFor="let column of visibleColumns(); trackBy: customTrackBy"
                [matColumnDef]="column.columnKey">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    [disabled]="!column.enableSorting"
                    class="border-r"
                    >{{ column.columnName }}</th
                >
                <td
                    mat-cell
                    *matCellDef="let row; index as rowIndex"
                    (click)="toggleCellSelection($event, column.columnKey, row.__index__, rowIndex)"
                    [ngClass]="[
                        isCellSelected(column.columnKey, row.__index__, rowIndex) ? 'selected' : '',
                        'border-r',
                        selection.isSelected(row) ? 'selected' : '',
                    ]"
                    [cdkContextMenuTriggerFor]="process_menu"
                    [cdkContextMenuTriggerData]="{ node: row[column.columnKey] }">
                    <ng-container
                        *ngIf="
                            getCustomColumn(column.columnKey) as content;
                            else defaultColumnContentCell
                        "
                        [ngTemplateOutlet]="content.templateRef"
                        [ngTemplateOutletContext]="{ $implicit: row }">
                    </ng-container>
                    <ng-template #defaultColumnContentCell>
                        <div
                            *ngIf="
                                !editedCell() ||
                                    (editedCell().rowIndex === rowIndex &&
                                        editedCell().__index__ === row.__index__ &&
                                        editedCell().key !== column.columnKey) ||
                                    editedCell().__index__ !== row.__index__;
                                then content
                            "></div>
                        <ng-template #content>
                            <div
                                *ngIf="
                                    column.contentType === 'timestamp';
                                    then showDateContent;
                                    else showContent
                                ">
                            </div>
                        </ng-template>
                        <ng-template #showDateContent>
                            <p (dblclick)="editCell(row, column.columnKey, rowIndex)">
                                {{
                                    row[column.columnKey] != null && row[column.columnKey] != ''
                                        ? (row[column.columnKey] | date)
                                        : '-'
                                }}
                            </p>
                        </ng-template>
                        <ng-template #showContent>
                            <p
                                (dblclick)="editCell(row, column.columnKey, rowIndex)"
                                [innerHTML]="
                                    row[column.columnKey] != null && row[column.columnKey] != ''
                                        ? row[column.columnKey]
                                        : '-'
                                "></p>
                        </ng-template>
                        <form
                            *ngIf="
                                editedCell() &&
                                editedCell().__index__ === row.__index__ &&
                                editedCell().rowIndex === rowIndex &&
                                editedCell().key === column.columnKey
                            "
                            class="formShown"
                            [formGroup]="form">
                            <formly-form
                                [form]="form"
                                [fields]="fields"
                                [model]="row"></formly-form>
                        </form>
                    </ng-template>
                </td>
            </ng-container>

            <!-- table definition -->
            <tr mat-header-row *matHeaderRowDef="getColumnKeys(); sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumnKeys()"></tr>
        </table>
    </div>

    <!-- Paginator -->
    <mat-paginator
        *ngIf="enablePagination"
        [pageSizeOptions]="[5, 10, 20, 50]"
        [pageSize]="10"
        aria-label="Select page of users"></mat-paginator>
</div>

<ng-template #process_menu let-data="node">
    <div class="menu-container mat-elevation-z8" cdkMenu>
        <div cdkMenuItem (click)="copy(data)">Copy</div>
    </div>
</ng-template>
